var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.currentUser.superAdmin)?_c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.packages,"loading":_vm.loading,"hide-default-footer":"","disable-pagination":""},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"close-on-content-click":false,"bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":""}},on),[_vm._v("mdi-focus-field-horizontal ")])]}}],null,true)},[_c('v-list',{staticClass:"overflow-y-auto",staticStyle:{"max-height":"500px"},attrs:{"dense":""}},[_c('v-list-item',[_c('v-list-item-title',[_vm._v(_vm._s(item.id))])],1)],1)],1)]}},{key:"item.image",fn:function(ref){
var item = ref.item;
return [_c('v-img',{attrs:{"src":item.imagePath,"contain":"","max-height":"80","max-width":"80"},on:{"error":function($event){item.bannerUrl = 'media/error/imageNotFound.png'}}})]}},{key:"header.add",fn:function(){return [_c('router-link',{attrs:{"to":{ name: 'PackageCreate' }}},[_c('v-avatar',{attrs:{"color":"teal","size":"26","rounded":""}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-plus")])],1)],1)]},proxy:true},{key:"item.created",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(new Date(item.created).toLocaleString())+" ")]}},{key:"item.priceAndCurrency",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.catalogItemPrices != null && item.catalogItemPrices.length > 0 ? item.catalogItemPrices[0].price + " " + item.catalogItemPrices[0].currency : "")+" ")]}},{key:"item.installmentDuration",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.installmentDurationInDays != null ? item.installmentDurationInDays === 365 ? "YEARLY" : item.installmentDurationInDays === 30 ? "MONTHLY" : "" : "")+" ")]}},{key:"item.permissions",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.permissions.length > 0 ? item.permissions[0].permissionName : "")+" "),_c('v-menu',{attrs:{"bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(item.permissions.length > 1)?_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":""}},on),[_vm._v("mdi-dots-horizontal ")]):_vm._e()]}}],null,true)},[_c('v-list',{staticClass:"overflow-y-auto",staticStyle:{"max-height":"500px"},attrs:{"dense":""}},_vm._l((item.permissions),function(item,i){return _c('v-list-item',{key:i},[_c('v-list-item-title',[_vm._v(_vm._s(item.externalId))])],1)}),1)],1)]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{attrs:{"color":"teal","size":"26","rounded":""}},[_c('router-link',{attrs:{"to":{
            name: 'PackageEdit',
            params: { packageId: item.id }
          }}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-pencil")])],1)],1)]}},{key:"item.add",fn:function(ref){
          var item = ref.item;
return [_c('v-avatar',{attrs:{"color":"red darken-4","size":"26","rounded":""}},[_c('v-icon',{attrs:{"dark":""},on:{"click":function($event){return _vm.delRecord(item)}}},[_vm._v("mdi-delete")])],1)]}}],null,false,984113162)}),_c('ConfirmDialog',{ref:"confirm"})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }